import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import he from 'he'

import Logo from '../assets/images/logo.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
    showServices: false,
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
    this.setState({ showServices: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _showServices = () => {
    this.setState({ showServices: true })
  }

  _hideServices = () => {
    this.setState({ showServices: false })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state
    let nextScroll = currentScroll > 0

    scrolled !== nextScroll && this.setState({ scrolled: nextScroll })
  }

  render() {
    let { checkout, data } = this.props
    let { offCanvas, scrolled, showServices } = this.state

    let headerClass = `header ${offCanvas ? 'active' : ''}`

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
      onMouseEnter: this._hideServices,
    }

    let serviceProps = {
      onClick: this._hideServices,
    }

    if (scrolled) headerClass += ' header--scrolled'

    let services = data.services.edges.map((edge, i) => (
      <ul key={i}>
        <li><Link className='title' to={`/service/${edge.node.slug}`} {...serviceProps}>{ he.decode(edge.node.title) }</Link></li>
        { edge.node.acf.services.map((sub, j) => (
            <li key={j}><Link to={`/service/${edge.node.slug}#${sub.title}`} {...serviceProps}>{ sub.title }</Link></li>
        )) }
      </ul>
    ))

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner container'>
            <nav className="header__nav header__nav--left">
              <ul>
                <li onMouseEnter={() => this._hideServices()}>
                  <Link to="/about" {...props}>About</Link>
                </li>
                <li onMouseEnter={() => this._showServices()} className={`${showServices && 'active' }`}>
                  Services
                </li>
                <li onMouseEnter={() => this._hideServices()}>
                  <Link to="/insights" {...props}>Insights</Link>
                </li>
              </ul>
            </nav>
            <div className={`hamburger ${offCanvas ? 'active' : ''}`} onClick={() => this.setState({ offCanvas: !offCanvas })}>
              <span className="lines"></span>
            </div>
            <Link to='/' title='Seiva' className='header__logo' {...props}>
              <img src={Logo} alt="Seiva" />
            </Link>
            <nav className={ checkout.lineItems.length ? 'header__nav header__nav--right header__nav mobile-cart' : 'header__nav header__nav--right' }>
              <ul>
                { checkout.lineItems.length ?
                  <li onMouseEnter={() => this._hideServices()}>
                    <Link to='/cart'>Cart <span className="number-wrap"><span>{ checkout.lineItems.length }</span></span></Link>
                  </li>
                  :
                  <li onMouseEnter={() => this._hideServices()}>
                    <Link to="/product/trypositive">TryPositive</Link>
                  </li>
                }
                <li onMouseEnter={() => this._hideServices()}>
                  <Link className="btn btn--dark" to="/contact-us" {...props}>Connect</Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <nav className={`off-canvas ${offCanvas ? 'active' : ''}`}>
          <ul>
            <li onClick={() => this._hideServices()}>
              <Link to="/about" {...props}>About</Link>
            </li>
            <li onClick={() => this._showServices()} className={`${showServices && 'active' }`}>
              Services
            </li>
            <li onClick={() => this._hideServices()}>
              <Link to="/insights" {...props}>Insights</Link>
            </li>
            <li onClick={() => this._hideServices()}>
              <Link to="/product/trypositive" {...props}>TryPositive</Link>
            </li>
          </ul>
        </nav>
        <div className={`header__services ${ showServices && 'header__services active' }`} onMouseLeave={() => this._hideServices()}>
          <div className="header__services-wrap container">
            <div className="header__services-title">
              <p>It all starts with a conversation. To get a better picture of where you’re at and where you want to be.</p>
            </div>
            <div className="header__services-col">
              { services[3] }
              { services[0] }
            </div>
            <div className="header__services-col">
              { services[4] }
              { services[1] }
            </div>
            <div className="header__services-col header__services-col--small">
              { services[2] }
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        services: allWpService {
          edges {
            node {
              slug
              title
              acf {
                services {
                  title
                  subtitle
                  content
                }
              }
            }
          }
        }
      }
      `}
    render={data => <Header data={data} {...props} />}
  />
)
